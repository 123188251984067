import { MessageTopics } from "./messageTopics";

export interface MarketDetails {
  id: string;
  title: string;
  suspended?: boolean;
}

export type Market = MarketDetails & {
  options: MarketOption[];
};

export interface MarketOption {
  playerId: string;
  title: string;
  odds: MarketOdds;
  id?: string;
  suspended?: boolean;
  marketDirection?: MarketDirection;
}

export interface MarketOdds {
  decimal?: string;
  fractional?: string;
  american?: string;
}

export enum MarketDirection {
  DEFAULT = "default",
  LENGTHENING = "lengthening",
  SHORTENING = "shortening",
}

export interface MarketsUpdateMessage {
  markets: Array<MarketDetails & Partial<Pick<Market, "options">>>;
}

export interface MarketsSuspendMessage {
  marketIds: string[];
  playerIds?: string[] | null;
}

export interface MarketsRemoveMessage {
  marketIds: string[];
  playerIds?: string[] | null;
}

export interface MarketsPlaceBetMessage {
  market: MarketDetails;
  option: MarketOption;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MARKETS_EVENT = {
  UPDATE: MessageTopics.MARKETS_UPDATE,
  SUSPEND: MessageTopics.MARKETS_SUSPEND,
  REMOVE: MessageTopics.MARKETS_REMOVE,
  PLACE_BET: MessageTopics.MARKETS_PLACE_BET,
} as const;

export type MarketsEvent = (typeof MARKETS_EVENT)[keyof typeof MARKETS_EVENT];

export type MarketsMessage =
  | MarketsUpdateMessage
  | MarketsSuspendMessage
  | MarketsRemoveMessage
  | MarketsPlaceBetMessage;
