export enum MessageTopics {
  CONTEXT_UPDATE = "context-update",
  OPTIONS_UPDATE = "options-update",
  SELECTION_UPDATE = "selection-update",
  CLEAR_SELECTIONS = "clear-selections",
  HANDSHAKE_ATTEMPT = "handshake-attempt",
  HANDSHAKE_REPLY = "handshake-reply",
  HANDSHAKE_FAILED = "handshake-failed",
  VIDEO_PLAYBACK_AUTH_REQUEST = "video-playback-auth-request",
  VIDEO_PLAYBACK_AUTH_RESPONSE = "video-playback-auth-response",
  BETLINK_OPERATOR_AUTH_TOKEN_REQUEST = "betlink-operator-auth-token-request",
  BETLINK_OPERATOR_AUTH_TOKEN_RESPONSE = "betlink-operator-auth-token-response",
  APP_LOADED = "app-loaded",
  CURRENT_EVENT_ID_UPDATE = "current-event-id-update",
  MARKETS_UPDATE = "markets-update",
  MARKETS_SUSPEND = "markets-suspend",
  MARKETS_REMOVE = "markets-remove",
  MARKETS_PLACE_BET = "markets-place-bet",
}
