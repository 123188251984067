import { eventCenterLite, eventCentre, eventCentreClient } from "./lib";
import { MessageTopics } from "./types";
import * as eventCentreMarkets from "./types/markets";
import { decodeJSONParameter, encodeJSONParameter } from "./utils";

const eventCentreUtils = {
  MessageTopics,
  encodeJSONParameter,
  decodeJSONParameter,
};

export * from "./types";
export {
  eventCenterLite,
  eventCentre,
  eventCentreClient,
  eventCentreMarkets,
  eventCentreUtils,
};

export default {
  eventCentreClient,
  eventCenterLite,
  eventCentre,
  eventCentreUtils,
  eventCentreMarkets,
};
